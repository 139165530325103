<script setup lang="ts">
import type { Api } from '@rialtic/api'
import { useEditor } from '~/stores/editor'

defineProps<{
  policy: Pick<Api.Policy, 'id' | 'name' | 'latest_review_status'>
}>()

const editor = useEditor()
</script>

<template>
  <NuxtLink
    :to="`/policy-editor/${policy.id}`"
    class="block flex h-16 flex-col items-start justify-between overflow-hidden px-4 py-2"
  >
    <div class="mb-1 flex w-full items-center justify-between">
      <div class="body-1 pointer-events-none pr-1">
        <pre class="text-neutral-800">{{ policy.id }}</pre>
      </div>
      <div class="flex items-center space-x-1">
        <UiLabel
          v-if="policy.latest_review_status"
          class="whitespace-nowrap"
          :class="{
            'bg-warning': policy.latest_review_status === 'Pending Review',
            'bg-secondary text-white':
              policy.latest_review_status === 'Published',
            'bg-success text-white': policy.latest_review_status === 'Approved',
          }"
        >
          {{ policy.latest_review_status }}
        </UiLabel>

        <PolicyPin :policy-id="policy.id" />
      </div>
    </div>
    <p class="body-2 w-full truncate font-semibold text-neutral-900">
      {{ policy.name }}
    </p>
  </NuxtLink>
</template>
